// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-batch-status-update-js": () => import("./../../../src/pages/batchStatusUpdate.js" /* webpackChunkName: "component---src-pages-batch-status-update-js" */),
  "component---src-pages-completed-orders-js": () => import("./../../../src/pages/completedOrders.js" /* webpackChunkName: "component---src-pages-completed-orders-js" */),
  "component---src-pages-customers-change-password-by-token-js": () => import("./../../../src/pages/customers/changePasswordByToken.js" /* webpackChunkName: "component---src-pages-customers-change-password-by-token-js" */),
  "component---src-pages-customers-forgot-password-js": () => import("./../../../src/pages/customers/forgotPassword.js" /* webpackChunkName: "component---src-pages-customers-forgot-password-js" */),
  "component---src-pages-customers-summary-js": () => import("./../../../src/pages/customers/summary.js" /* webpackChunkName: "component---src-pages-customers-summary-js" */),
  "component---src-pages-design-options-summary-js": () => import("./../../../src/pages/designOptionsSummary.js" /* webpackChunkName: "component---src-pages-design-options-summary-js" */),
  "component---src-pages-design-section-summary-js": () => import("./../../../src/pages/designSectionSummary.js" /* webpackChunkName: "component---src-pages-design-section-summary-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-invite-js": () => import("./../../../src/pages/invite.js" /* webpackChunkName: "component---src-pages-invite-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-order-groupings-js": () => import("./../../../src/pages/orderGroupings.js" /* webpackChunkName: "component---src-pages-order-groupings-js" */),
  "component---src-pages-orders-history-js": () => import("./../../../src/pages/orders/history.js" /* webpackChunkName: "component---src-pages-orders-history-js" */),
  "component---src-pages-orders-pending-refunds-js": () => import("./../../../src/pages/orders/pendingRefunds.js" /* webpackChunkName: "component---src-pages-orders-pending-refunds-js" */),
  "component---src-pages-orders-sales-order-js": () => import("./../../../src/pages/orders/salesOrder.js" /* webpackChunkName: "component---src-pages-orders-sales-order-js" */),
  "component---src-pages-orders-tag-js": () => import("./../../../src/pages/orders/tag.js" /* webpackChunkName: "component---src-pages-orders-tag-js" */),
  "component---src-pages-prodsheet-js": () => import("./../../../src/pages/prodsheet.js" /* webpackChunkName: "component---src-pages-prodsheet-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-reports-js": () => import("./../../../src/pages/reports.js" /* webpackChunkName: "component---src-pages-reports-js" */),
  "component---src-pages-settings-js": () => import("./../../../src/pages/settings.js" /* webpackChunkName: "component---src-pages-settings-js" */),
  "component---src-pages-suppliers-prodsheet-js": () => import("./../../../src/pages/suppliers/prodsheet.js" /* webpackChunkName: "component---src-pages-suppliers-prodsheet-js" */),
  "component---src-pages-suppliers-summary-js": () => import("./../../../src/pages/suppliers/summary.js" /* webpackChunkName: "component---src-pages-suppliers-summary-js" */)
}

